import intersection from 'lodash/intersection';
import isNil from 'lodash/isNil';

import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import { parseSelectFilterOptions, isOriginInUse } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import routeConfiguration from '../../routing/routeConfiguration';
import { SHOW_FEATURED_SECTION_STORAGE_KEY } from './SearchPage.constants';

const flatten = (acc, val) => acc.concat(val);

const RADIX = 10;

export const DEBOUNCE_WAIT_TIME = 400;

/**
 * Validates a filter search param agains a filters configuration.
 *
 * All invalid param names and values are dropped
 *
 * @param {String} queryParamName Search parameter name
 * @param {Object} paramValue Search parameter value
 * @param {Object} filters Filters configuration
 */
export const validURLParamForExtendedData = (queryParamName, paramValueRaw, filters) => {
  // Resolve configuration for this filter
  const filterConfig = filters.find(f => {
    const isArray = Array.isArray(f.queryParamNames);
    return isArray
      ? f.queryParamNames.includes(queryParamName)
      : f.queryParamNames === queryParamName;
  });

  const paramValue = paramValueRaw.toString();

  if (filterConfig) {
    const { min, max } = filterConfig.config || {};

    if (['SelectSingleFilter', 'SelectMultipleFilter'].includes(filterConfig.type)) {
      // Pick valid select options only
      const allowedValues = filterConfig.config.options.map(o => o.key);
      const searchMode = filterConfig.config.searchMode;
      const isSchemaTypeMultiEnum = filterConfig.config.schemaType === 'multi-enum';
      const valueArray = parseSelectFilterOptions(paramValue);
      const validValues = intersection(valueArray, allowedValues).join(',');

      return validValues.length > 0
        ? {
            [queryParamName]:
              isSchemaTypeMultiEnum && searchMode ? `${searchMode}:${validValues}` : validValues,
          }
        : {};
    } else if (filterConfig.type === 'PriceFilter') {
      // Restrict price range to correct min & max
      const valueArray = paramValue ? paramValue.split(',') : [];
      const validValues = valueArray.map(v => {
        return v < min ? min : v > max ? max : v;
      });
      return validValues.length === 2 ? { [queryParamName]: validValues.join(',') } : {};
    } else if (filterConfig) {
      // Generic filter - remove empty params
      return paramValue.length > 0 ? { [queryParamName]: paramValue } : {};
    }
  }
  return {};
};

/**
 * Checks filter param value validity.
 *
 * Non-filter params are dropped.
 *
 * @param {Object} params Search params
 * @param {Object} filters Filters configuration
 */
export const validFilterParams = (params, filters) => {
  const filterParamNames = filters.map(f => f.queryParamNames).reduce(flatten, []);
  const paramEntries = Object.entries(params);

  return paramEntries.reduce((validParams, entry) => {
    const [paramName, paramValue] = entry;

    return filterParamNames.includes(paramName)
      ? {
          ...validParams,
          ...validURLParamForExtendedData(paramName, paramValue, filters),
        }
      : { ...validParams };
  }, {});
};

/**
 * Checks filter param value validity.
 *
 * Non-filter params are returned as they are.
 *
 * @param {Object} params Search params
 * @param {Object} filters Filters configuration
 */
export const validURLParamsForExtendedData = (params, filters) => {
  const filterParamNames = filters.map(f => f.queryParamNames).reduce(flatten, []);
  const paramEntries = Object.entries(params);

  return paramEntries.reduce((validParams, entry) => {
    const [paramName, paramValue] = entry;

    return filterParamNames.includes(paramName)
      ? {
          ...validParams,
          ...validURLParamForExtendedData(paramName, paramValue, filters),
        }
      : { ...validParams, [paramName]: paramValue };
  }, {});
};

// extract search parameters, including a custom URL params
// which are validated by mapping the values to marketplace custom config.
export const pickSearchParamsOnly = (params, filters, sortConfig) => {
  const { address, origin, bounds, ...rest } = params || {};
  const boundsMaybe = bounds ? { bounds } : {};
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};
  const filterParams = validFilterParams(rest, filters);
  const sort = rest[sortConfig.queryParamName];
  const sortMaybe = sort ? { sort } : {};

  return {
    ...boundsMaybe,
    ...originMaybe,
    ...filterParams,
    ...sortMaybe,
  };
};

export const createSearchResultSchema = (listings, mainSearchData, intl) => {
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const { address, pub_keywords } = mainSearchData;
  const keywordsMaybe = pub_keywords ? `"${pub_keywords}"` : null;
  const searchTitle =
    address || keywordsMaybe || intl.formatMessage({ id: 'SearchPage.schemaForSearch' });
  const schemaDescription = intl.formatMessage({ id: 'SearchPage.schemaDescription' });
  const schemaTitle = intl.formatMessage(
    { id: 'SearchPage.schemaTitle' },
    { searchTitle, siteTitle }
  );

  const schemaListings = listings.map((l, i) => {
    const title = l.attributes.title || '';
    const pathToItem = createResourceLocatorString('ListingPage', routeConfiguration(), {
      id: l.id.uuid,
      slug: createSlug(title),
    });
    return {
      '@type': 'ListItem',
      position: i,
      url: `${config.canonicalRootURL}${pathToItem}`,
      name: title,
    };
  });

  const schemaMainEntity = JSON.stringify({
    '@type': 'ItemList',
    name: searchTitle,
    itemListOrder: 'http://schema.org/ItemListOrderAscending',
    itemListElement: schemaListings,
  });
  return {
    title: schemaTitle,
    description: schemaDescription,
    schema: {
      '@context': 'http://schema.org',
      '@type': 'SearchResultsPage',
      description: schemaDescription,
      name: schemaTitle,
      mainEntity: [schemaMainEntity],
    },
  };
};

export const parseMin = (min, currentMax) => value => {
  const parsedValue = Number.parseInt(value, RADIX);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue < min ? min : parsedValue > currentMax ? currentMax : parsedValue;
};

export const parseMax = (max, currentMin) => value => {
  const parsedValue = Number.parseInt(value, RADIX);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue < currentMin ? currentMin : parsedValue > max ? max : parsedValue;
};

export const parseRange = range => {
  if (isNil(range)) {
    return null;
  }
  range = range.toString();
  const [minPrice, maxPrice] = !!range
    ? range.split(',').map(v => Number.parseInt(v, RADIX))
    : [];
  if (!isNil(minPrice) && isNil(maxPrice)) {
    return { minPrice, maxPrice: minPrice };
  } else if (isNil(minPrice) && !isNil(maxPrice)) {
    return { minPrice: maxPrice, maxPrice };
  }
  // Note: we compare to null, because 0 as minPrice is falsy in comparisons.
  return !!range && minPrice != null && maxPrice != null ? { minPrice, maxPrice } : null;
};

export const rangeValueToSearchParam = value => {
  if (!value) {
    return null;
  }
  const splitet = value.split(',');
  const minValue = Number(splitet[0]);
  const maxValue = Number(splitet[1]);
  if (minValue === maxValue) {
    return minValue;
  }
  return `${minValue},${maxValue + 1}`;
};

export const childLandingMetadataToSearchMetadata = metadata => {
  if (!metadata) {
    return {};
  }
  return {
    ...(metadata.categoryName && {
      category: { label: metadata.categoryName },
    }),
    ...(metadata.makerName && {
      maker: { label: metadata.makerName },
    }),
    ...(metadata.modelFamilyName && {
      modelFamily: { label: metadata.modelFamilyName },
    }),
    ...(metadata.modelName && {
      model: { label: metadata.modelName },
    }),
  };
};

export const pageMetaTags = searchMetadata => {
  let pageTitle;
  let pageDescription;

  if (searchMetadata.category && searchMetadata.maker && searchMetadata.model) {
    pageTitle = `${searchMetadata.maker.label} ${searchMetadata.model.label} for Sale | Aircraft For Sale`;
    pageDescription = `Discover the Best Selection of ${searchMetadata.maker.label} ${searchMetadata.model.label}s for Sale. `
                      + `Find more ${searchMetadata.category.label}s Available Today!`;
  } else if (searchMetadata.category && searchMetadata.maker && searchMetadata.modelFamily) {
    pageTitle = `${searchMetadata.maker.label} ${searchMetadata.modelFamily.label} for Sale | Aircraft For Sale`;
    pageDescription = `Discover the Best Selection of ${searchMetadata.maker.label} ${searchMetadata.modelFamily.label}s for Sale. `
                      + `Find more ${searchMetadata.category.label}s Available Today!`;
  } else if (searchMetadata.category && searchMetadata.maker) {
    pageTitle = `${searchMetadata.maker.label} ${searchMetadata.category.label}s for Sale | Aircraft For Sale`;
    pageDescription = `Discover the Best Selection of ${searchMetadata.maker.label}'s ${searchMetadata.category.label}s for Sale. `
                      + `Find more ${searchMetadata.category.label}s Available Today!`;
  } else if (searchMetadata.category) {
    pageTitle = `${searchMetadata.category.label}s for Sale | Aircraft For Sale`;
    pageDescription = `Discover the Best Selection of ${searchMetadata.category.label}s for Sale. `
                      + `Search and Find more ${searchMetadata.category.label}s Available Today!`;
  }

  return {
    pageTitle,
    pageDescription,
  };
};

export const isEmptySearchMetadata = metadata => {
  return Object.keys(metadata).every(i => !metadata[i]);
};

export const fixRangeFilters = value => {
  const keys = Object.keys(value);
  if (keys.length) {
    const key = keys[0];
    const keyValue = value[key];
    if (keyValue) {
      const splited = keyValue.split(',');
      if (splited.length === 2) {
        if (splited[1] === splited[0]) {
          value = { [key]: splited[0] };
        }
      }
    }
  }
  return value;
};

export const sessionSwitchKey = key => {
  const isWindowDefined = typeof window !== 'undefined';
  const sessionValue = isWindowDefined && window.sessionStorage.getItem(key);
  return isWindowDefined && sessionValue ? JSON.parse(sessionValue) : true;
}
