import classNames from 'classnames';
import React from 'react';
// Import shared components
import { FieldSelect } from '../../../components';
// Import modules from this directory
import css from './EditListingWizard.module.css';

const CustomFieldEnum = props => {
  const {
    name,
    id,
    options,
    label,
    placeholder,
    validate,
    schemaType,
    disabled,
    isSelected,
    notRequired,
    defaultValue,
    className,
  } = props;

  return options && schemaType === 'enum' ? (
    <FieldSelect
      className={classNames(className, css.detailsSelect, {[css.detailsSelectSelected]: isSelected}, {[css.detailsSelectNotRequired]: notRequired})}
      name={name}
      id={id}
      label={label}
      validate={validate}
      disabled={disabled}
      defaultValue={defaultValue}
    >
      {placeholder && (
        <option disabled value="">
          {placeholder}
        </option>
      )}
      {options.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

CustomFieldEnum.defaultProps = {
  schemaType: 'enum',
};

export default CustomFieldEnum;
